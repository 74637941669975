.auth-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #161616;
}

.auth-header {
  background-color: rgba(22, 22, 22, 0.9);
  backdrop-filter: blur(8px);
  border-bottom: 1px solid #333;
  position: fixed;
  width: 100%;
  z-index: 1000;
}

.auth-logo-text {
  font-weight: 600;
  color: #24a148;
}

.auth-content-wrapper {
  display: flex;
  flex: 1;
  height: calc(100vh - 3rem);
  margin-top: 3rem;
}

/* Left side - Image section */
.auth-image-section {
  display: none;
  position: relative;
  background-size: cover;
  background-position: center;
  transition: background-image 1s ease-in-out;
  overflow: hidden;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.5) 100%);
  z-index: 1;
}

.image-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  color: white;
  text-align: center;
  width: 80%;
  animation: fadeIn 0.8s ease-in-out;
}

.image-title {
  font-size: 2.5rem;
  font-weight: 300;
  margin-bottom: 1rem;
  opacity: 0;
  animation: slideUp 0.8s ease-out forwards 0.3s;
}

.image-description {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  opacity: 0;
  animation: slideUp 0.8s ease-out forwards 0.5s;
}

.image-features {
  display: flex;
  justify-content: center;
  gap: 2rem;
  opacity: 0;
  animation: slideUp 0.8s ease-out forwards 0.7s;
}

.feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.feature svg {
  fill: #24a148;
}

.image-indicators {
  position: absolute;
  bottom: 2rem;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  z-index: 2;
}

.indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.4);
  cursor: pointer;
  transition: all 0.3s ease;
}

.indicator.active {
  background-color: #24a148;
  transform: scale(1.2);
}

/* Right side - Form section */
.auth-form-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.auth-form-container {
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
}

.auth-form-tile {
  background-color: #262626;
  border-radius: 8px;
  padding: 2.5rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.5s ease-in-out;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.auth-form-wrapper {
  text-align: center;
}

.auth-title {
  font-size: 2rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
  color: #ffffff;
  text-align: center;
}

.auth-subtitle {
  font-size: 1rem;
  color: #c6c6c6;
  margin-bottom: 2rem;
  text-align: center;
}

.auth-input {
  margin-bottom: 1.5rem;
  text-align: left;
  animation: fadeIn 0.5s ease-in-out;
}

.auth-input input {
  background-color: #333333;
  border-bottom: 1px solid #565656;
  color: #f4f4f4;
  transition: border-bottom-color 0.3s ease;
}

.auth-input input:focus {
  border-bottom-color: #24a148;
}

.auth-input label {
  color: #f4f4f4;
}

/* Add extra space after password input */
#password-wrapper {
  margin-bottom: 2rem !important;
}

.auth-submit-button {
  width: 100%;
  margin-top: 1.5rem;
  min-height: 3rem;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

/* Custom green button styling */
.green-button {
  background-color: #24a148 !important;
}

.green-button:hover {
  background-color: #0e8c3a !important;
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(36, 161, 72, 0.3);
}

.green-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 5px rgba(36, 161, 72, 0.3);
}

.green-button.loading {
  background-color: #1e8a3e !important;
}

.green-button.loading::after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  animation: loading 1.5s infinite;
}

.auth-toggle {
  margin-top: 2rem;
  text-align: center;
  color: #c6c6c6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth-toggle p {
  margin: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.auth-toggle-button {
  color: #24a148 !important;
  padding: 0 0.5rem !important;
  min-height: 24px !important;
  height: auto !important;
  margin-left: 0.5rem !important;
  display: inline-flex !important;
  align-items: center !important;
  transition: all 0.3s ease;
}

.auth-toggle-button:hover {
  color: #0e8c3a !important;
  text-decoration: underline;
}

.auth-error {
  margin-bottom: 1.5rem;
  animation: shake 0.5s ease-in-out;
}

#confirmPassword-wrapper {
  margin-bottom: 2rem !important;
  transition: all 0.3s ease;
}

#confirmPassword-wrapper.hidden {
  height: 0;
  margin: 0 !important;
  opacity: 0;
  overflow: hidden;
}

/* Animations */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-5px);
  }
  20%, 40%, 60%, 80% {
    transform: translateX(5px);
  }
}

@keyframes loading {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

/* Media Queries */
@media (min-width: 768px) {
  .auth-image-section {
    display: block;
    flex: 1;
  }
  
  .auth-form-section {
    flex: 1;
  }
}

@media (max-width: 767px) {
  .auth-form-tile {
    box-shadow: none;
    padding: 1.5rem;
  }
  
  .auth-content-wrapper {
    margin-top: 3rem;
  }
}