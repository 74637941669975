.landing-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #1e1e1e; /* Changed from #f4f4f4 to dark gray */
}

.landing-header {
  background-color: #0f0f0f;
  border-bottom: 1px solid #393939;
  height: 3rem;
  display: flex;
  align-items: center;
}

.landing-content {
  flex: 1;
  background-color: #1e1e1e; /* Changed from #f4f4f4 to match landing-container */
  padding: 2rem;
}

/* Header buttons */
.header-button {
  height: 100%;
  color: white !important;
  padding: 0 1rem !important;
}

.header-button:hover {
  background-color: #393939 !important;
}

/* Create Program Dropdown */
.create-program-wrapper {
  position: relative;
}

.create-program-button {
  display: flex !important;
  align-items: center;
  background-color: #24a148 !important;
  color: white !important;
  border-radius: 4px;
  padding: 0 1rem !important;
  margin-right: 1rem;
  height: 3rem !important;
  min-width: fit-content;
  width: auto !important;
}

.create-program-button:hover {
  background-color: #0e8c3a !important;
}

.create-program-button span {
  margin-right: 0.5rem;
  font-size: 0.875rem;
  font-weight: 400;
  white-space: nowrap;
}

.program-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #262626;
  border: 1px solid #393939;
  border-radius: 4px;
  width: 200px;
  margin-top: 0.25rem;
  overflow: hidden;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.program-dropdown button {
  width: 100%;
  padding: 0.75rem 1rem;
  text-align: left;
  background: none;
  border: none;
  color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.15s;
}

.program-dropdown button:hover {
  background-color: #393939;
}

.program-dropdown button svg {
  margin-right: 0.75rem;
  color: #24a148;
}

/* Programs Table Styles */
.programs-table-container {
  background-color: #262626; /* Changed from white to dark gray */
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3); /* Increased shadow opacity */
  margin-bottom: 2rem;
}

.programs-title {
  font-size: 1.5rem;
  font-weight: 400;
  margin: 1.5rem 1rem 0.5rem 1rem;
  color: #f4f4f4; /* Changed from #161616 to light gray */
}

.create-program-table-button {
  background-color: #24a148 !important;
}

.create-program-table-button:hover {
  background-color: #0e8c3a !important;
}

/* Change the selection banner color to green */
.cds--batch-actions {
  background-color: #24a148 !important;
}

.cds--batch-actions:hover, 
.cds--batch-actions:focus {
  background-color: #0e8c3a !important;
}

/* The blue "items selected" section */
.cds--batch-summary {
  color: white !important;
  background-color: #24a148 !important;
}

/* Ensure the whole batch action bar is green */
.cds--batch-actions:before {
  background-color: #24a148 !important;
}

.cds--batch-summary__para {
  color: white !important;
}

.cds--btn--primary.cds--batch-summary__cancel {
  background-color: transparent !important;
  color: white !important;
}

/* Table action buttons */
.cds--batch-actions .cds--btn--primary {
  background-color: #24a148 !important;
  border-color: #24a148 !important;
  color: white !important;
}

.cds--batch-actions .cds--btn--primary:hover {
  background-color: #0e8c3a !important;
  border-color: #0e8c3a !important;
}

/* Ghost buttons in the batch actions */
.cds--batch-actions .cds--btn--ghost {
  color: white !important;
  border-color: white !important;
}

.cds--batch-actions .cds--btn--ghost:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

/* Specifically target the Download and Delete buttons */
.cds--batch-actions .cds--action-list .cds--btn {
  background-color: #24a148 !important;
  border-color: #24a148 !important;
  color: white !important;
}

.cds--batch-actions .cds--action-list .cds--btn:hover {
  background-color: #0e8c3a !important;
  border-color: #0e8c3a !important;
}

/* Cancel button */
.cds--batch-actions .cds--action-list .cds--btn--ghost {
  background-color: transparent !important;
  color: white !important;
  border-color: white !important;
}

.cds--batch-actions .cds--action-list .cds--btn--ghost:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

/* Empty state styles */
.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 8rem);
  text-align: center;
  color: #c6c6c6; /* Changed from #525252 to lighter gray */
  background-color: #262626; /* Changed from white to dark gray */
  border-radius: 8px;
  padding: 3rem;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

.empty-state h2 {
  font-size: 1.75rem;
  font-weight: 400;
  margin-bottom: 1rem;
  color: #f4f4f4; /* Changed from #161616 to light gray */
}

.empty-state p {
  font-size: 1rem;
  margin-bottom: 2rem;
}

.create-first-program {
  background-color: #24a148 !important;
}

.create-first-program:hover {
  background-color: #0e8c3a !important;
}

/* About modal styles */
.about-text {
  font-size: 1rem;
  line-height: 1.5;
  color: #f4f4f4; /* Changed from #161616 to light gray */
}

/* Make the modal's close button green */
.green-modal-button {
  background-color: #24a148 !important;
}

.green-modal-button:hover {
  background-color: #0e8c3a !important;
}

/* Override Carbon's modal styling to change button color */
.cds--modal-footer .cds--btn--primary {
  background-color: #24a148;
}

.cds--modal-footer .cds--btn--primary:hover {
  background-color: #0e8c3a;
}

/* Table Specific Dark Mode Adjustments */
.cds--data-table {
  background-color: #262626;
  color: #f4f4f4;
}

.cds--data-table-header {
  background-color: #333333;
}

.cds--data-table th {
  background-color: #333333;
  color: #f4f4f4;
}

.cds--data-table td {
  color: #f4f4f4;
  border-top: 1px solid #393939;
}

.cds--data-table tr:hover td {
  background-color: #333333;
  color: #ffffff;
}

/* Search and toolbar area */
.cds--table-toolbar {
  background-color: #262626;
}

.cds--search-input {
  background-color: #333333;
  color: #f4f4f4;
}

/* Custom styles for "User's Workout Programs" heading */
.user-programs-title {
  color: #f4f4f4;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}